<template>
  <span class="profile-label">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'BaseLabel',
}
</script>

<style lang="scss" scoped>
.profile-label {
  font-size: 0.8em;
  color: #45b1aa;
}
</style>
