<template>
  <div>
    <BaseLabel>{{ label }}</BaseLabel>

    <p>
      {{ value ? value : '-' }}
    </p>
  </div>
</template>

<script>
import BaseLabel from './BaseLabel.vue'

export default {
  name: 'BaseInput',
  components: { BaseLabel },
  props: {
    label: {
      type: String,
      default: '',
    },

    value: {
      type: [String, Number],
      default: '-',
    },
  },
  emits: [],
  methods: {},
}
</script>

<style lang="scss" scoped>
p {
  margin-top: 10px;
  padding-bottom: 5px;
  min-width: 100px;

  border-bottom: 0.5px solid #45b1aa;
}
</style>
